import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek, shiftMonth, startOfMonth, endOfMonth, shiftHours, dateFormat, tomorrow } from "../../utils/dateFunctions";
import ReportDateSelector from "../../shared/ReportDateSelector";
import { getOysterGrades, gradeSort } from "../../definitions/oysters";
import MonthDateSelector from "../../shared/MonthDateSelector";
import { textfieldDateFormat, dayCompare } from '../../utils/dateFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import WarehouseSelector from "../../components/WarehouseSelector";
import SalesOrdersCreate from './FunctionalComp/SalesOrdersCreate';
import FormModal from '../../components/FormModal';
import ProjectSales from './Forms/ProjectSales';
import SalesOrdersEditable from './Tables/SalesOrdersEditable';
import { checkAccess } from '../../utils/roleFunctions';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { DownloadButton } from '../../utils/downloadUtils';

import { Table, TableContainer, TableRow, TableCell, TableBody, TableHead, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import { handlePagination } from 'app/utils/apiFunction';
import { numberFormat } from 'app/utils/numberFunctions';
import WarehouseToggle from 'app/components/WarehouseTogglewithBoth';
import getShipSaleData from './Function/GetShipSaleList';
const Canceled = 'Canceled';
const teams = [
    { id: 'isBagged', name: 'Live Bagged' },
    { id: 'isBoxed', name: 'Live Boxed' },
    { id: 'isFrozen', name: 'Frozen' },
    { id: 'isHalfshell', name: 'Halfshell' },
]
const SalesOrdersPage = (props) => {
    const firstStart = new Date();
    const firstEnd = shiftMonth(new Date(), 2);
    const uri = process.env.REACT_APP_BASE_URL;
    const [startDate, setStartDate] = React.useState(tomorrow(tomorrow(startOfWeek(startOfMonth((firstStart))))));
    const [endDate, setEndDate] = React.useState(tomorrow(tomorrow(endOfWeek(endOfMonth(firstEnd)))));
    const [type, setType] = React.useState(0);
    const [data, setData] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [mode, setMode] = React.useState(0);
    const [edit, setEdit] = React.useState(false);
    const [series, setSeries] = React.useState(false);
    const [location, setLocation] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const [grades, setGrades] = React.useState([]);
    const [salesOrders, setSalesOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const requiredEditAccess = { key: ACCESS_SECTIONS.SALES, value: ACCESS.EDIT };
    const reloadFunc = () => {
        setReload(!reload);
    }
    // React.useEffect(() => {
    //     const abortController = new AbortController();
    //     setSalesOrders([]);
    //     setLoading(true);
    //     handlePagination(fetch, uri + ('/SalesOrder/Get'), textfieldDateFormat(startDate), textfieldDateFormat(endDate), {}, abortController).then((data) => {
    //         setSalesOrders(data.filter((da) => {
    //             return da.status !== Canceled && da.status !== 'Closed' && da.status !== 'Cancelled' && da.orderType === 'SO'
    //         }));
    //         setLoading(false);
    //     });
    //     return () => {
    //         abortController.abort();
    //     };
    // }, [reload, startDate, endDate]);

    React.useEffect(() => {
        const abortController = new AbortController();
        setSalesOrders([]);
        getShipSaleData({}, textfieldDateFormat(startDate), textfieldDateFormat(endDate), abortController, setLoading, setSalesOrders,true);
        return () => {
            abortController.abort();
        };
    }, [startDate, endDate, reload]);

    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);

    const organiseData = (data, md) => {
        var totals = {};
        var lst = []
        // total each grade (data.salesOrderLines.stockItem.gradeId)
        data.map((sorder) => {
            var order = sorder;
            if(location){
                order = sorder[String(location.myobid)]
            }
            console.log(order)
                        if (md === 0) {
                        for (var i = 0; i < grades.length; i++) {
                        if (totals[String(grades[i].id)]) {
                            totals[String(grades[i].id)] += order[String(grades[i].id)];

                        } else {
                            totals[String(grades[i].id)] = order[String(grades[i].id)];
                        }
                        // if (line.stockItem?.gradeId == 11) {
                        //     console.log(line)
                        //     lst.push(line)
                        // }
                    }
                }
                else if (md === 1) {
                    for (var i = 0; i < teams.length; i++) {
                        var team = teams[i];
                        if (order[team.id]) {
                            if (totals[team.id]) {
                                totals[team.id] += Number(order[team.id]);
                            } else {
                                totals[team.id] = Number(order[team.id]);
                            }
                        }
                    }
                }

        })
        return totals;
    }
    
    const rows = React.useMemo(
        () => {
            var movingDate = new Date(startDate);
            var rows = [];
            var allTot = 0;
            if (salesOrders && salesOrders.length > 0) {
                while (movingDate <= endDate) {
                    var listOfOrders = salesOrders.filter((order) => (dayCompare(new Date(order.orderDateRaw), new Date(movingDate))));
                    var totalsData = organiseData(listOfOrders, mode);
                    var cpyDate = shiftHours(new Date(movingDate), 24);
                    rows.push({ ...totalsData, title: dateFormat(movingDate) + ' ' + movingDate.toLocaleString('default', { weekday: 'short' }), date: dateFormat(movingDate), start: new Date(movingDate), end: cpyDate });
                    movingDate = shiftHours(movingDate, 24);
                }
            }
            return rows;
        },
        [salesOrders, location, mode,grades])
    const columns = React.useMemo(() => {
        var cols = [];
        if (mode === 1) {
            teams.map((team) => {
                cols.push({ title: team.name, id: team.id });
            })
        } else if (mode === 0) {
            grades?.map((og) => {
                cols.push({ title: og.abv, id: og.id });
            })
        }
        return cols;
    }, [grades, mode])
    const tableRowSettings = {
    }
    const tableCellSettings = {
        padding: '5px 10px'
    }
    const formatForExcel = (data) => {
        var rows = [];
        data.map((row) => {
            var newRow = {
                Date: row.date,
                Day: (row.start.toLocaleString('default', { weekday: 'long' }))
            };
            columns.map((col) => {
                newRow[col.title] = row[col.id];
            })
            rows.push(newRow);
        });
        return rows;
    }
    console.log(startDate, endDate, rows)
    var gTotalToal = 0;
    return (
        <React.Fragment>

            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <MonthDateSelector startDate={firstStart} setStartDate={setStartDate} endDate={firstEnd} setEndDate={setEndDate} />
                    {/* <ReportDateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} type={type} setType={setType} /> */}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <WarehouseToggle location={location} setLocation={setLocation} />
                </Grid>

            </Grid>

            {!loading ? <Grid container spacing={3.5}>

                <Grid item xs={12}>
                    <Paper >
                        <TableContainer>
                            <Typography variant="h4" style={{ padding: '10px', display: 'inline' }}>Sales Orders</Typography>
                            <ToggleButtonGroup value={mode} exclusive onChange={(e, value) => { setMode(value) }}>
                                <ToggleButton value={0}>By Grade</ToggleButton>
                                <ToggleButton value={1}>By Team</ToggleButton>
                            </ToggleButtonGroup>
                            <DownloadButton data={formatForExcel(rows)} useText={false} start={true} />
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        {columns.map((col) => {
                                            return <TableCell>{col.title}</TableCell>
                                        })}
                                        <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => {
                                        //if sat or sun and has no data hide
                                        var hasData = false;
                                        for (var i = 0; i < columns.length; i++) {
                                            if (row[columns[i].id] > 0) {
                                                hasData = true;
                                                break;
                                            }
                                        }

                                        const DataRow = () => {
                                            var rowTotal = 0;
                                            if ((row.start.getDay() == 6 || row.start.getDay() == 0) && !hasData) {
                                                return null;
                                            }
                                            return (<TableRow style={tableRowSettings}>
                                                <TableCell style={tableCellSettings}>{row.title}</TableCell>
                                                {columns.map((col) => {
                                                    rowTotal = rowTotal + Number(row[col.id] ? row[col.id] : 0);
                                                    return <TableCell style={tableCellSettings}>{numberFormat(row[col.id])}</TableCell>
                                                })}
                                                <TableCell style={{ ...tableCellSettings, fontWeight: 'bold' }}>{numberFormat(rowTotal)}</TableCell>
                                            </TableRow>)
                                        }
                                        if ((row.start.getDay() == 0) && index >= 6) {
                                            var eoWTotal = 0
                                            return (<React.Fragment>
                                                <DataRow />
                                                <TableRow style={{ backgroundColor: 'lightgrey', fontWeight: 'bold', ...tableRowSettings }}>
                                                    <TableCell style={{ fontWeight: 'bold', ...tableCellSettings }}>Week Total</TableCell>
                                                    {columns.map((col) => {
                                                        var total = 0;
                                                        for (var i = index - 6; i <= index; i++) {
                                                            total += Number(rows[i][col.id] ? rows[i][col.id] : 0);
                                                        }
                                                        eoWTotal += total;
                                                        return <TableCell style={{ fontWeight: 'bold', ...tableCellSettings }}>{numberFormat(total)}</TableCell>
                                                    })}
                                                    <TableCell style={{ ...tableCellSettings, fontWeight: 'bold' }}>{numberFormat(eoWTotal)}</TableCell>
                                                </TableRow>
                                            </React.Fragment>)
                                        } else {
                                            return (<DataRow />)
                                        }
                                    })}
                                    <TableRow style={{ backgroundColor: 'lightgrey', fontWeight: 'bold', borderTop: '3px solid black', borderBottom: '3px solid black', ...tableRowSettings }}>
                                        <TableCell style={{ fontWeight: 'bold', ...tableCellSettings }}>Grand Total</TableCell>
                                        {columns.map((col) => {
                                            var total = 0;
                                            for (var i = 0; i < rows.length; i++) {
                                                total += Number(rows[i][col.id] ? rows[i][col.id] : 0);
                                            }
                                            gTotalToal += total;
                                            return <TableCell style={{ fontWeight: 'bold', ...tableCellSettings }}>{numberFormat(total)}</TableCell>
                                        })}
                                        <TableCell style={{ ...tableCellSettings, fontWeight: 'bold' }}>{numberFormat(gTotalToal)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid> : <CircularProgress />}
        </React.Fragment >
    );
};

//const RollOutButton = (props) => {
//    const [open, setOpen] = React.useState(false);
//    const [completed, setCompleted] = React.useState(false);

//    const openForm = () => {
//        setOpen(true);

//    }
//    const handleClose = () => {
//        setCompleted(false);
//        setOpen(false);
//    }


//    return <React.Fragment>
//        <FormModal open={open} handleClose={handleClose} title={"Project Sales"} >
//            <ProjectSales access={props.access} reload={props.reloadFunc} handleClose={handleClose} completed={completed} />
//        </FormModal>
//        <Button onClick={openForm} variant="contained" style={{ marginBottom: '5px' }}>{"Project Sales forward"}</Button>

//    </React.Fragment>
//}

export default SalesOrdersPage;
