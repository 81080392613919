import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow, textfieldDateFormat, shiftHours, dateFormat } from "../../utils/dateFunctions";
import { getAPIData, getAPIDataParams, getAPIDataParamsReturn } from "../../utils/apiFunction";
import { numberFormat } from '../../utils/numberFunctions';
import DatePicker from "../../shared/DatePicker";
import SelectWarehouse from '../../components/SelectWarehouse';
import { WAREHOUSEGROUPS, WAREHOUSEMATCHES } from "../../utils/constants/warehouse";
import { useJumboTheme } from "@jumbo/hooks";
import { renderToString } from 'react-dom/server';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TableContainer, TableRow, Table, TableCell, TableHead, TableBody, Typography, CircularProgress, Paper } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, Line } from 'recharts';
import DateSelector from 'app/shared/DateSelector';
import { hexToHSL, hslToHex } from "../../utils/colourFunctions";
import SupplierSelect from 'app/components/SupplierSelect';
import Estuaries from '../Procurement/Estuaries';
import EstuaryStatusSelect from 'app/components/EstuaryStatusSelect';
import HarvestAreaSelect from 'app/components/HarvestAreaSelect';
import TextField from '@mui/material/TextField';
import CustomerSelect from 'app/components/CustomerSelect';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Div from "@jumbo/shared/Div";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import AddIcon from '@mui/icons-material/Add';
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { Download, Star, Warehouse } from '@mui/icons-material';
import DataTableStandard from 'app/components/DataTableStandard';
import Link from "@mui/material/Link";
import MailIcon from '@mui/icons-material/Mail';
import DownloadIcon from '@mui/icons-material/Download';
import Product from 'app/shared/widgets/PopularProducts/Product';
import { convertToCSV } from 'app/utils/downloadUtils';

const Traceability = (props) => {
    const { theme } = useJumboTheme();
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(shiftHours(new Date(), -24 * 7));
    const [endDate, setEndDate] = React.useState(new Date());
    const [location, setLocation] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [supplier, setSupplier] = React.useState(null);
    const [estuary, setEstuary] = React.useState(null);
    const [harvestArea, setHarvestArea] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);
    const [customer, setCustomer] = React.useState(null);
    const [prn, setPrn] = React.useState(null);
    const [prnList, setPrnList] = React.useState([]);
    var showColumns = []; //only cahgne in geneterate body data
    const [topLevelList, setTopLevelList] = React.useState([]);
    const [loadingDownload, setLoadingDownload] = React.useState(false);
    const productPalette = theme.palette.oysterProducts;
    const [downloadAllTrace, setDownloadAllTrace] = React.useState(false);
    const reloadFunc = () => {
        setReload(!reload);
    }
    React.useEffect(() => {
        var abortController = new AbortController();
        if (downloadAllTrace) {
            downloadAll(abortController);
            return () => {
                console.log('aborting');
                abortController.abort();
            }
        }
    }, [downloadAllTrace]);

    const searchTrace = async () => {
        var prns = null;
        if (prn) {
            var prnsrec = await getAPIDataParamsReturn('/Inventory/SearchPRN', { LotNbrSubString: prn })
            prns = prnsrec.map((pr) => {
                return { LotNbr: pr }
            });
        } else if (supplier) {
            var prnsrec = await getAPIDataParamsReturn('/Reports/GetLotNoBySupplier', { StartDate: textfieldDateFormat(startDate), EndDate: textfieldDateFormat(endDate), SupplierId: supplier.id })
            prns = prnsrec.map((pr) => {
                return { LotNbr: pr }
            });
        } else if (estuary && !harvestArea) {
            var prnsrec = await getAPIDataParamsReturn('/Reports/GetLotNoByEstuary', { StartDate: textfieldDateFormat(startDate), EndDate: textfieldDateFormat(endDate), EstuaryId: estuary.id })
            prns = prnsrec.map((pr) => {
                return { LotNbr: pr }
            });
        } else if (harvestArea) {
            var prnsrec = await getAPIDataParamsReturn('/Reports/GetLotNoByHarvestArea', { StartDate: textfieldDateFormat(startDate), EndDate: textfieldDateFormat(endDate), HarvestAreaId: harvestArea.id })
            prns = prnsrec.map((pr) => {
                return { LotNbr: pr }
            });
        }
        //make each lotnbr uniuqe
        prns = prns.filter((prn, index, self) =>
            index === self.findIndex((t) => (
                t.LotNbr === prn.LotNbr
            ))
        );
        setPrnList(prns.map((prn) => { return { ...prn, selected: true } }));
        reloadFunc();
    }
    const removePrns = (ind) => {
        var prns = [...prnList];
        prns[ind].selected = false;
        setPrnList(prns);
    }
    const addPrns = (ind) => {
        var prns = [...prnList];
        prns[ind].selected = true;
        setPrnList(prns);
    }
    const getButtonText = () => {
        if (prn) {
            return 'Search PRN: ' + prn;
        } else if (customer) {
            return 'Search Customer: ' + customer.name;
        } else if (supplier) {
            return 'Search Supplier: ' + supplier.name;
        } else if (estuary && !harvestArea) {
            return 'Search Estuary: ' + estuary.description;
        } else if (harvestArea) {
            return 'Search Harvest Area: ' + harvestArea.description;
        } else {
            return 'Search';
        }
    }
    const downloadAll = async (abortController) => {
        setLoadingDownload(true);
        console.log(prnList);
        var prmArr = [];
        var filteredPrnList = prnList.filter((prn) => {
            return prn.selected;
        });
        prmArr.push(getAPIDataParamsReturn('/Reports/GetStockOnHand', { LotSerialNbrs: toCSVURL(filteredPrnList) }, abortController));
        prmArr.push(getAPIDataParamsReturn('/Reports/GetStockInProcess', { LotSerialNbrs: toCSVURL(filteredPrnList) }, abortController));
        prmArr.push(getAPIDataParamsReturn('/Reports/GetCustomers', { LotSerialNbr: toCSVURL(filteredPrnList) }, abortController).then(async (data) => {
            var prmArrInner = [];
            var groupCount = 25;
            for (var i = 0; i < data.data.length; i++) {
                var d = data.data[i];
                if (i>groupCount){
                    prmArrInner = await Promise.all(prmArrInner);
                    groupCount = groupCount + 25;
                }
                prmArrInner.push(getAPIDataParamsReturn('/Reports/GetCustomerSalesData', { LotSerialNbr: toCSVURL(filteredPrnList), CustomerId: d.customer?.id }, abortController));
            }
            var listOfCust = await Promise.all(prmArrInner);
            return listOfCust.map((d, ind) => {
                return { ...d, ...data.data[ind] };
            });
        }));
        var allDataList = [];
        var returnedData = await Promise.all(prmArr);
        var invData = returnedData[0]?.data?.map((d) => {
            return {
                LotSerialNbr: d.lotSerialNbr,
                StockCode: d.stockItem?.myobid,
                Stockitem: d.stockItem?.description,
                Quantity: d.quantity,
                Warehouse: d.warehouse?.description,
                "Line/Bench": "N/A",
                Process: "N/A",
                ProductionDate: "N/A",
                "Sales Order": "N/A",
                "Shipment": "N/A",
                "Price": "N/A",
                isShipped: "N/A",
                Type: 'Inventory'
            }
        });
        var processData = returnedData[1]?.data?.map((d) => {
            return {
                LotSerialNbr: d.lotSerialNbr,
                StockCode: d.stockItem?.myobid,
                Stockitem: d.stockItem?.description,
                Quantity: d.quantity,
                Warehouse: d.warehouse?.description,
                "Line/Bench": d.processLine.description,
                Process: d.processLine.isHalfshell ? 'Halfshell' : 'Live',
                ProductionDate: "N/A",
                "Sales Order": "N/A",
                "Shipment": "N/A",
                "Price": "N/A",
                isShipped: "N/A",
                Type: 'Process'
            }
        });
        console.log(returnedData[2]);
        var custData = returnedData[2]?.reduce((prev, curr) => {
            return [...prev, ...curr.orderData?.reduce((p, c) => {
                return [...p, ...c.lines.map((d) => {
                    return {
                        LotSerialNbr: d.lotSerialNbr,
                        StockCode: d.stockItem?.myobid,
                        Stockitem: d.stockItem?.description,
                        Quantity: d.quantity,
                        Warehouse: d.warehouse?.description,
                        "Line/Bench": "N/A",
                        Process: "N/A",
                        ProductionDate: d.date,
                        "Sales Order": c.salesOrderMYOBID,
                        "Shipment": c.shipmentMYOBID,
                        "Price": d.price,
                        isShipped: c.shipmentMYOBID === 'Not Shipped' ? 'No' : 'Yes',
                        Type: 'Shipment'
                    }
                })]
            }, [])]
        }, []);
        console.log(custData);

        allDataList = [...invData, ...processData, ...custData];
        setLoadingDownload(false);
        const csvData = new Blob([convertToCSV(allDataList)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        const fileName = 'ProductRecall' + new Date().toLocaleString();
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadAllTrace(false);

    }
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={2}>
                </Grid>
                <Grid item xs={12} md={2}>
                    <DatePicker label="Start Date" date={startDate} setDate={(date) => setStartDate(date)} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <DatePicker label="End Date" date={endDate} setDate={(date) => setEndDate(date)} />
                </Grid>
            </Grid>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} md={2}>
                    <TextField
                        id="outlined-required"
                        label="PRN"
                        value={prn}
                        onChange={(e) => setPrn(e.target.value)}
                        noValidate
                        sx={{ width: '80%' }}
                        disabled={customer || estuary || harvestArea || supplier || completed}
                        InputProps={{
                            endAdornment: (<IconButton size="small" onClick={() => setPrn('')}>
                                <ClearIcon />
                            </IconButton>)
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={2}>
                    <SupplierSelect widthAuto state={supplier} setState={setSupplier} disabled={prn || customer || estuary || harvestArea || completed} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <EstuaryStatusSelect widthAuto state={estuary} setState={setEstuary} disabled={prn || customer || supplier || harvestArea || completed} />
                </Grid>
                <Grid item xs={12} md={2}>
                    <HarvestAreaSelect estuary={estuary} widthAuto state={harvestArea} setState={setHarvestArea} disabled={prn || customer || supplier || completed} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button onClick={searchTrace} variant="contained" color="primary">{getButtonText()}</Button>
                    {prnList && prnList.length > 0 ? (!loadingDownload ? <IconButton onClick={() => { setDownloadAllTrace(true) }}>
                        <DownloadIcon />
                    </IconButton> : <CircularProgress />) : null}
                </Grid>
            </Grid>
            <JumboScrollbar
                autoHeight
                autoHeightMax={150}
                autoHeightMin={20}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Grid container spacing={2} mb={1} style={{ marginTop: '5px', marginLeft: '10px' }}>
                    {prnList.length > 0 ? prnList.map((prn, ind) => {
                        return <Div style={{ color: (prn.selected ? 'green' : 'red') }}><Typography style={{ display: 'inline' }}>{prn.LotNbr}</Typography>{prn.selected ? <IconButton size="small" onClick={() => removePrns(ind)}>
                            <ClearIcon />
                        </IconButton> : <IconButton size="small" onClick={() => addPrns(ind)}>
                            <AddIcon />
                        </IconButton>}</Div>
                    }) : null}
                </Grid>
            </JumboScrollbar>
            <Div>
                <Grid container spacing={4} mb={1}>
                    <Grid item xs={12} sm={12} md={6}>
                        <InventoryReport reloadFunc={reloadFunc} prnList={prnList} reload={reload} />

                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <ProcessingStatus reloadFunc={reloadFunc} prnList={prnList} reload={reload} />

                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <Customers reloadFunc={reloadFunc} prnList={prnList} reload={reload} />

                    </Grid>
                </Grid>
            </Div>
        </React.Fragment >
    );
};
export default Traceability;

const Collapsable = (props) => {
    const handleClick = () => {
        props.setOpen(!props.open);
    }
    if (props.open) {
        return (
            <IconButton onClick={handleClick}>
                <ExpandLessIcon />
            </IconButton>
        );

    } else {
        return (
            <IconButton onClick={handleClick}>
                <ExpandMoreIcon />
            </IconButton>
        );
    }
}
const toCSVURL = (prns) => {
    var prnsCSV = prns.reduce((p, c) => {
        if (p === '') return c.LotNbr;
        return p + ',' + c.LotNbr;
    }, '');
    return prnsCSV
}
function usePrevPropValue(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const InventoryReport = (props) => {
    const [show, setShow] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [localPRNs, setLocalPRNs] = React.useState(props.prnList);
    const [invData, setInvData] = React.useState([]);
    const prevReload = usePrevPropValue(props.reload);
    const filteredInvData = useMemo(() => {
        return invData.filter((d) => {
            var listObj = props.prnList.find((prn) => {
                return prn.LotNbr === d.lotSerialNbr
            })
            return listObj?.selected;
        })
    }, [props.prnList, invData]);
    const getInvData = () => {
        if (props.prnList && props.prnList.length > 0) {
            setLoading(true);

            getAPIDataParamsReturn('/Reports/GetStockOnHand', { LotSerialNbrs: toCSVURL(props.prnList) }).then((data) => {
                setLoading(false);
                console.log(data)
                data = data?.data?.map((d) => {
                    return {
                        ...d,
                        warehouseName: d.warehouse?.description,
                        stockItemName: d.stockItem?.description,
                        stockCode: d.stockItem?.myobid
                    }
                });
                setInvData(data);
            });
        }
    }
    React.useEffect(() => {
        var refreshNeeded = false;
        if (localPRNs && (localPRNs.length === props.prnList.length)) {
            for (var i = 0; i < props.prnList.length; i++) {
                if (localPRNs[i].LotNbr !== props.prnList[i].LotNbr) {
                    refreshNeeded = true;
                    break;
                }
            }
        } else {
            refreshNeeded = true;
        }
        if (prevReload !== props.reload) {
            refreshNeeded = true;
        }
        if (refreshNeeded) {
            setLocalPRNs(props.prnList);
            getInvData()
        }
    }, [props.prnList, props.reload]);

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: props.height ? props.height : '400px',
        responsive: 'standard',
        rowsPerPage: 1000,
    };

    var cols = [
        {
            name: 'stockCode',
            label: 'Stock Code',
        },
        {
            name: 'stockItemName',
            label: 'Stock Item',

        },
        {
            name: 'quantity',
            label: 'Quantity',

        },

        {
            name: 'warehouseName',
            label: 'Warehouse',

        },
        {
            name: 'lotSerialNbr',
            label: 'Lot Serial Nbr',

        }
    ];
    return <JumboCardQuick
        title={<Typography variant={"h2"} mb={0}>{"Stock On Hand"}<Collapsable open={show} setOpen={setShow} /></Typography>}
        wrapperSx={{
            p: 0,
            backgroundColor: 'background.paper',
            zIndex: 0,
            '&:last-child': {
                pb: 0
            }
        }}
    >

        {show && (props.prnList?.length > 0) ? (loading ? <CircularProgress /> :
            <DataTableStandard
                data={filteredInvData ? filteredInvData : []}
                title={<Typography variant={"h2"} mb={0}>{""}</Typography>}
                columns={cols}
                options={options}
                returnSearch={!loading}
                reload={props.reloadFunc}
                shrink={true} />
        ) : null}
    </JumboCardQuick>
}

const ProcessingStatus = (props) => {
    const [show, setShow] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [localPRNs, setLocalPRNs] = React.useState(props.prnList);
    const [invData, setInvData] = React.useState([]);
    const prevReload = usePrevPropValue(props.reload);
    const filteredInvData = useMemo(() => {
        return invData.filter((d) => {
            var listObj = props.prnList.find((prn) => {
                return prn.LotNbr === d.lotSerialNbr
            })
            return listObj?.selected;
        })
    }, [props.prnList, invData]);
    const getInvData = () => {
        if (props.prnList && props.prnList.length > 0) {
            setLoading(true);

            getAPIDataParamsReturn('/Reports/GetStockInProcess', { LotSerialNbrs: toCSVURL(props.prnList) }).then((data) => {
                setLoading(false);
                console.log(data)
                data = data?.data?.map((d) => {
                    return {
                        ...d,
                        warehouseName: d.warehouse?.description,
                        stockItemName: d.stockItem?.description,
                        stockCode: d.stockItem?.myobid,
                        process: d.processLine.isHalfshell ? 'Halfshell' : 'Live',
                        processLine: d.processLine.description
                    }
                });
                setInvData(data);
            });
        }
    }
    React.useEffect(() => {
        var refreshNeeded = false;
        if (localPRNs && (localPRNs.length === props.prnList.length)) {
            for (var i = 0; i < props.prnList.length; i++) {
                if (localPRNs[i].LotNbr !== props.prnList[i].LotNbr) {
                    refreshNeeded = true;
                    break;
                }
            }
        } else {
            refreshNeeded = true;
        }
        if (prevReload !== props.reload) {
            refreshNeeded = true;
        }
        if (refreshNeeded) {
            setLocalPRNs(props.prnList);
            getInvData()
        }
    }, [props.prnList, props.reload]);

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: props.height ? props.height : '400px',
        responsive: 'standard',
        rowsPerPage: 1000,
    };

    var cols = [
        {
            name: 'stockCode',
            label: 'Stock Code',
        },
        {
            name: 'stockItemName',
            label: 'Stock Item',

        },
        {
            name: 'quantity',
            label: 'Quantity',

        },
        {
            name: 'process',
            label: 'Process'
        },
        {
            name: 'processLine',
            label: 'Line/Bench'
        },
        {
            name: 'warehouseName',
            label: 'Warehouse',

        },
        {
            name: 'lotSerialNbr',
            label: 'Lot Serial Nbr',

        }
    ];
    return <JumboCardQuick
        title={<Typography variant={"h2"} mb={0}>{"In Process"}<Collapsable open={show} setOpen={setShow} /></Typography>}
        wrapperSx={{
            p: 0,
            backgroundColor: 'background.paper',
            zIndex: 0,
            '&:last-child': {
                pb: 0
            }
        }}
    >

        {show && (props.prnList?.length > 0) ? (loading ? <CircularProgress /> :
            <DataTableStandard
                data={filteredInvData ? filteredInvData : []}
                title={<Typography variant={"h2"} mb={0}>{""}</Typography>}
                columns={cols}
                options={options}
                returnSearch={!loading}
                reload={props.reloadFunc}
                shrink={true} />
        ) : null}
    </JumboCardQuick>
}
const Customers = (props) => {
    const [show, setShow] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [loadingCustomer, setLoadingCustomer] = React.useState(false);
    const [localPRNs, setLocalPRNs] = React.useState(props.prnList);
    const [invData, setInvData] = React.useState([]);
    const [customerData, setCustomerData] = React.useState(null);
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const { theme } = useJumboTheme();
    const prevReload = usePrevPropValue(props.reload);
    const filteredInvData = useMemo(() => {
        return invData.filter((d) => {
            for (var i = 0; i < d.lsnShipped.length; i++) {
                if (props.prnList.find((prn) => {
                    return d.lsnShipped[i].lotSerialNbr === prn.LotNbr
                })?.selected) {
                    return true;
                }
            }
            for (var i = 0; i < d.lsnUnShipped.length; i++) {
                if (props.prnList.find((prn) => {
                    return d.lsnUnShipped[i].lotSerialNbr === prn.LotNbr
                })?.selected) {
                    return true;
                }
            }
            return false;
        })
    }, [props.prnList, invData]);
    const filteredCustomerData = useMemo(() => {
        if (customerData === null) return null;
        var newOrderData = [];
        for (var i = 0; i < customerData?.orderData?.length; i++) {
            var newLines = customerData?.orderData?.[i].lines?.filter((d) => {
                if (props.prnList.find((prn) => {
                    return d.lotSerialNbr === prn.LotNbr
                })?.selected) {
                    return true;
                }
                return false;
            });
            if (newLines.length > 0) {
                newOrderData.push({ ...customerData?.orderData?.[i], lines: newLines });
            }
        }
        console.log(newOrderData);
        return { ...customerData, orderData: newOrderData };
    }, [customerData, props.prnList]);
    const getInvData = () => {
        if (props.prnList && props.prnList.length > 0) {
            setLoading(true);

            getAPIDataParamsReturn('/Reports/GetCustomers', { LotSerialNbr: toCSVURL(props.prnList) }).then((data) => {
                setLoading(false);
                console.log(data)
                data = data?.data?.map((d) => {
                    return {
                        ...d,
                        ...d.customer,
                    }
                });
                setInvData(data);
            });
        }
    }
    React.useEffect(() => {
        var refreshNeeded = false;
        if (localPRNs && (localPRNs.length === props.prnList.length)) {
            for (var i = 0; i < props.prnList.length; i++) {
                if (localPRNs[i].LotNbr !== props.prnList[i].LotNbr) {
                    refreshNeeded = true;
                    break;
                }
            }
        } else {
            refreshNeeded = true;
        }
        if (prevReload !== props.reload) {
            refreshNeeded = true;
            setCustomerData(null);
        }
        if (refreshNeeded) {
            setLocalPRNs(props.prnList);
            getInvData()
        }
    }, [props.prnList, props.reload]);
    const openCustomer = (rowData, rowMeta) => {
        var selectedCustomer = filteredInvData[rowMeta.dataIndex];
        setLoadingCustomer(true);
        setSelectedCustomer(selectedCustomer);
        getAPIDataParamsReturn('/Reports/GetCustomerSalesData', { LotSerialNbr: toCSVURL(props.prnList), CustomerId: selectedCustomer.id }).then((data) => {
            setLoadingCustomer(false);
            console.log(data)
            setCustomerData(data);
        });
    }
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: props.height ? props.height : '400px',
        responsive: 'standard',
        rowsPerPage: 1000,
        onRowClick: (rowData, rowMeta) => {
            openCustomer(rowData, rowMeta);
        },
        setRowProps: row => {
            var selRow = row[0];
            if (selRow === selectedCustomer?.myobid) {
                return {
                    style: { background: 'lightYellow' }
                };
            } else {
                return {};
            }
        },
    };
    const optionsCustomer = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: props.height ? props.height : '400px',
        responsive: 'standard',
        rowsPerPage: 1000,
        expandableRows: true,
        rowsExpanded: filteredCustomerData?.orderData?.map((d, i) => { return i }),
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        isRowExpandable: (dataIndex, expandedRows) => {
            return true;
        },
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            var lineData = filteredCustomerData?.orderData?.[rowMeta.dataIndex];
            console.log(lineData);
            var colspan = 1;
            return (
                <React.Fragment>
                    <TableRow >
                        <TableCell colSpan={2} style={{ padding: '0 0 0 15px', fontWeight: 'bold', fontSize: 'smaller', }} >LotSerialNbr</TableCell>
                        <TableCell colSpan={colspan} style={{ padding: '0px', fontWeight: 'bold', fontSize: 'smaller' }} >Stock Code</TableCell>
                        <TableCell colSpan={colspan} style={{ padding: '0px', fontWeight: 'bold', fontSize: 'smaller' }} >StockItem</TableCell>
                        <TableCell colSpan={colspan} style={{ padding: '0px', fontWeight: 'bold', fontSize: 'smaller' }} >Quantity</TableCell>
                        <TableCell colSpan={colspan} style={{ padding: '0px', fontWeight: 'bold', fontSize: 'smaller' }} >Price</TableCell>
                        <TableCell colSpan={colspan} style={{ padding: '0px', fontWeight: 'bold', fontSize: 'smaller' }} >Warehouse</TableCell>
                    </TableRow>

                    {
                        lineData?.lines?.map((line) => {
                            return (
                                <TableRow >
                                    <TableCell colSpan={2} style={{ padding: '0 0 0 15px', }} >{line.lotSerialNbr}</TableCell>
                                    <TableCell style={{ padding: '0px', }} >{line.stockItem?.myobid}</TableCell>
                                    <TableCell style={{ padding: '0px', }} >{line.stockItem?.description}</TableCell>
                                    <TableCell style={{ padding: '0px', }} >{line.quantity}</TableCell>
                                    <TableCell style={{ padding: '0px', }} >{line.price}</TableCell>
                                    <TableCell style={{ padding: '0px', }} >{line.warehouse?.description}</TableCell>
                                </TableRow>
                            );
                        })
                    }
                </React.Fragment>


            );

        },
        setRowProps: row => {
            var selRow = row[2];
            if (selRow === ("Not Shipped")) {
                return {
                    style: { background: theme.palette.oysterProducts.open }
                };
            } else {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            }
        },
    }
    var cols = [
        {
            name: 'myobid',
            label: 'MYOBId',

        },
        {
            name: 'name',
            label: 'Customer',
        },
        {
            name: 'quantityUnShipped',
            label: 'Unshipped (doz)',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return filteredInvData?.[dataIndex]?.lsnUnShipped.reduce((p, c) => {
                        if (props.prnList.find((prn) => {
                            return c.lotSerialNbr === prn.LotNbr
                        })?.selected) {
                            return p + c.quantity;
                        } else {
                            return p;
                        }
                    }
                        , 0)
                },
            }

        },
        {
            name: 'quantityShipped',
            label: 'Shipped (doz)',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return filteredInvData?.[dataIndex]?.lsnShipped.reduce((p, c) => {
                        if (props.prnList.find((prn) => {
                            return c.lotSerialNbr === prn.LotNbr
                        })?.selected) {
                            return p + c.quantity;
                        } else {
                            return p;
                        }
                    }
                        , 0)
                },
            }

        }
    ];
    var colsCustomer = [
        {
            name: 'dateFormat',
            label: 'Production Date',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return filteredCustomerData?.orderData?.[dataIndex]?.productionDate ? dateFormat(filteredCustomerData?.orderData?.[dataIndex]?.productionDate) : 'N/A'
                },
            }
        },
        {
            name: 'salesOrderMYOBID',
            label: 'Sales Order',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                    return <Link href={'https://aoc.myobadvanced.com/Main?CompanyID=' + tenant + '&ScreenId=SO301000&OrderType=SO&OrderNbr=' + filteredCustomerData?.orderData?.[dataIndex]?.salesOrderMYOBID} target='_blank'>{filteredCustomerData?.orderData?.[dataIndex]?.salesOrderMYOBID}</Link>
                },
            }

        },
        {
            name: 'shipmentMYOBID',
            label: 'Shipment',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                    if (filteredCustomerData?.orderData?.[dataIndex]?.shipmentMYOBID !== 'Not Shipped') {
                        return <Link href={'https://aoc.myobadvanced.com/Main?CompanyID=' + tenant + '&ScreenId=SO302000&ShipmentNbr=' + filteredCustomerData?.orderData?.[dataIndex]?.shipmentMYOBID} target='_blank'>{filteredCustomerData?.orderData?.[dataIndex]?.shipmentMYOBID}</Link>
                    } else {
                        return 'Not Shipped'
                    }
                },
            }

        },

        {
            name: 'quantity',
            label: 'Quantity',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return filteredCustomerData?.orderData?.[dataIndex]?.quantity ? numberFormat((filteredCustomerData?.orderData?.[dataIndex]?.quantity)) : 'N/A'
                },
            }

        },
        {
            name: 'totalCost',
            label: 'Total Cost',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return filteredCustomerData?.orderData?.[dataIndex]?.totalCost ? ('$' + numberFormat((filteredCustomerData?.orderData?.[dataIndex]?.totalCost))) : 'N/A'
                },
            }
        },
        {
            name: '',
            label: '',

        }
    ]

    const emailMessage = (customer, data) => {
        var mailHtm = "<h1> Sample Email <h1>";
        var emailto = "";
        var emailsubject = "Product Recall";
        var emlCont = 'To: ' + emailto + '\n';

        var table = "Hi,<br/><br/>Please see below for the details of the product recall.<br/><br/>";
        table += "<table style='background:#C1E1C1;border:1px solid black;text-align:center; width:1000px; border-collapse: collapse;'><tr style='border:1px solid black;'><th style='border:1px solid black;'>Production Date</th><th style='border:1px solid black;'>Sales Order Id</th><th style='border:1px solid black;'>Shipment Id</th><th style='border:1px solid black;'>Quantity</th></tr>";
        for (var i = 0; i < data?.orderData?.length; i++) {
            table += "<tr style='background:#A7C7E7;border:1px solid black;'><td style='border:1px solid black;'>" + (data?.orderData?.[i]?.productionDate ? dateFormat(data?.orderData?.[i]?.productionDate) : 'N/A') + "</td><td style='border:1px solid black;'>" + data?.orderData?.[i]?.salesOrderMYOBID + "</td><td style='border:1px solid black;'>" + (data?.orderData?.[i]?.shipmentMYOBID !== 'Not Shipped' ? data?.orderData?.[i]?.shipmentMYOBID : 'Not Shipped') + "</td><td style='border:1px solid black;'>" + (data?.orderData?.[i]?.quantity ? (numberFormat((data?.orderData?.[i]?.quantity)) + ' doz') : 'N/A') + "</td></tr>";
            table += "<tr style='border:1px solid black;'><td colspan='4' style='border:1px solid black;'>";
            table += "<table style='font-size:smaller; width:1000px; text-align:center; border:1px solid black; border-collapse: collapse;'>";
            // table += "<tr style='border:1px solid black;'><th style='border:1px solid black;'>Stock Code</th><th style='border:1px solid black;'>Stockitem</th><th style='border:1px solid black;'>Quantity</th><th style='border:1px solid black;'>Warehouse</th></tr>";
            for (var j = 0; j < data?.orderData?.[i]?.lines?.length; j++) {
                table += "<tr style='border:1px solid black;'><td style='border:1px solid black;'>" + data?.orderData?.[i]?.lines?.[j]?.stockItem?.myobid + "</td><td style='border:1px solid black;'>" + data?.orderData?.[i]?.lines?.[j]?.stockItem?.description + "</td><td style='border:1px solid black;'>" + (data?.orderData?.[i]?.lines?.[j]?.quantity ? (numberFormat(data?.orderData?.[i]?.lines?.[j]?.quantity) + ' doz') : '') + "</td><td style='border:1px solid black;'>" + data?.orderData?.[i]?.lines?.[j]?.lotSerialNbr + "</td><td style='border:1px solid black;'>" + data?.orderData?.[i]?.lines?.[j]?.warehouse?.description + "</td></tr>";
            }
            table += "</table></td></tr>";
        }
        table += "</table>";

        emlCont += 'Subject: ' + emailsubject + '\n';
        emlCont += 'X-Unsent: 1' + '\n';

        emlCont += 'Content-Type: text/html' + '\n';
        emlCont += '' + '\n';

        emlCont += "<!DOCTYPE html><html><head></head><body>" + table + "</body></html>";
        console.log(emlCont);
        var textFile = null;
        var data = new Blob([emlCont], { type: 'text/plain' });
        if (textFile !== null) {
            window.URL.revokeObjectURL(textFile);
        }

        textFile = window.URL.createObjectURL(data);
        console.log(textFile);



        return textFile;
    }


    return <JumboCardQuick
        title={<Typography variant={"h2"} mb={0}>{"Customers"}<Collapsable open={show} setOpen={setShow} /></Typography>}
        wrapperSx={{
            p: 0,
            backgroundColor: 'background.paper',
            zIndex: 0,
            '&:last-child': {
                pb: 0
            }
        }}
    >

        {show && (props.prnList?.length > 0) ? (loading ? <CircularProgress /> :
            <Grid container spacing={2} mb={1} >
                <Grid item xs={12} lg={4}>
                    <DataTableStandard
                        data={filteredInvData ? filteredInvData : []}
                        title={<Typography variant={"h2"} mb={0}>{""}</Typography>}
                        columns={cols}
                        options={options}
                        returnSearch={!loading}
                        reload={props.reloadFunc}
                        shrink={true} />
                </Grid>
                <Grid item xs={12} lg={8}>
                    {loadingCustomer ? <CircularProgress /> :
                        (filteredCustomerData ? <DataTableStandard
                            data={filteredCustomerData?.orderData ? filteredCustomerData.orderData : []}
                            title={<Typography variant={"h2"} mb={0}><MailTo customerName={selectedCustomer?.name} email={emailMessage(selectedCustomer, filteredCustomerData)} />{selectedCustomer?.name} - Orders</Typography>}
                            columns={colsCustomer}
                            options={optionsCustomer}
                            returnSearch={!loadingCustomer}
                            reload={props.reloadFunc}
                            shrink={true} /> : null)
                    }
                </Grid>
            </Grid>
        ) : null}
    </JumboCardQuick>
}

const MailTo = ({ email, customerName }) => {
    const [loading, setLoading] = React.useState(false);
    //create ann iconbutton with a mail icon to send an email wiht mailto

    return <IconButton>
        {!loading ? <Link
            to='#'
            href={email}
            target="_blank"
            download={customerName + ".eml"}
        >
            <MailIcon />
        </Link> : <CircularProgress />}
    </IconButton>

}
