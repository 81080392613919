import React from 'react';
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import { getAPIData, getAPIDataParams } from '../utils/apiFunction';
import { textfieldDateFormat, startOfWeek, shiftWeek } from '../utils/dateFunctions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import POSelectForm from '../pages/Processing/Forms/POSelectForm';
import FormModal from './FormModal';
import Button from "@mui/material/Button";

const HarvestAreaSelect = (props) => {
    const [ests, setEsts] = React.useState([]);
    const [value, setValue] = React.useState(props.state);
    const [inputValue, setInputValue] = React.useState('');
    const filterVals = (list) => {
        var has = list.reduce((p, c) => {
            return [...p, ...c.harvestAreas]
        }, []).sort((a, b) => {
            return a.description > b.description ? 1 : -1;
        });
        var uniqueEsts = [];
        has.forEach((est) => {
            if (!uniqueEsts.find((ue) => ue.description === est.description)) {
                uniqueEsts.push(est);
            }
        });
        if (props.estuary && props.estuary.harvestAreas) {
            return (uniqueEsts.filter((est) => props.estuary.harvestAreas.find((ha) => (ha.id == est.id))));
        } else {
            return (uniqueEsts);
        }
    }
    React.useEffect(() => {
        getAPIData('/Estuaries/Get', setEsts);
    }, [])


    return (<Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.description}
        disablePortal
        id="estuarybox"
        options={filterVals(ests)}
        sx={props.widthAuto ? { width: 'auto' } : { width: 300 }}
        renderInput={(params) => <TextField {...params} label="Harvest Area" />}
    />);



}

export default HarvestAreaSelect;