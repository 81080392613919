import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { startOfWeek, undoDateFormat, textfieldDateFormat, dateFormat, adjustDateByStockItem } from "../../../utils/dateFunctions";
import ControlledTextField from "../../../components/ControlledTextField";
import Div from "@jumbo/shared/Div";
import IconButton from '@mui/material/IconButton';
import { useJumboTheme } from "@jumbo/hooks";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DatePicker from "../../../shared/DatePicker";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getOysterGrades, buildSpeciesList } from '../../../definitions/oysters';
import AddIcon from '@mui/icons-material/Add';
import FormModal from '../../../components/FormModal';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import StaffMember from "../../../components/StaffMember";
import SelectWarehouse from "../../../components/SelectWarehouse";
import { checkAccess } from '../../../utils/roleFunctions';
import { getAPIDataParamsReturn, postApi, getAPIData } from "../../../utils/apiFunction";
import { userInfo } from '../../../../adalConfig';
import { Table, TableBody, TableCell, TableHead, TableRow, ToggleButtonGroup, ToggleButton, Typography } from "@mui/material"
import { SelectTextFieldNew as SelectTextField } from "../../../components/SelectTextField";
import JumboSearch from '../../../../@jumbo/components/JumboSearch';
import { v4 as uuidv4 } from 'uuid';
const PriceForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [staffMember, setStaffMember] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [currStockItems, setcurrStockItems] = React.useState([]);
    const [date, setDate] = React.useState(props.date ? props.date : new Date());
    const [comments, setComments] = React.useState(null);
    const [currentStock, setCurrentStock] = React.useState(null);
    const [oysters, setOysters] = React.useState([]);
    const [returnData, setReturnData] = React.useState(null);
    const [stockItems, setStockItems] = React.useState([]);
    const [warehouse, setWarehouse] = React.useState(null);
    const [currentEntries, setCurrentEntries] = React.useState(null);
    const [outputsPassedGroup, setOutputsPassedGroup] = React.useState([]);
    const [reload, setReload] = React.useState(false);
    const [grades, setGrades] = React.useState([]);
    const [speciesList, setSpeciesList] = React.useState([]);
    const [fullList, setFullList] = React.useState([]);
    const [newLines, setNewLines] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [inventory, setInventory] = React.useState(null);
    const [createLoading, setCreateLoading] = React.useState(false);
    const [currentStocktake, setCurrentStocktake] = React.useState(null);
    const [editST, setEditST] = React.useState(false);
    const [newList, setNewList] = React.useState([]);
    const [sortKey, setSortKey] = React.useState({ main: 'expiryDate', second: null });
    const [filterChoice, setFilterChoise] = React.useState(('null-null'));
    const id = props.data ? props.data.id : '';
    const [user, setUser] = React.useState(userInfo());
    const frozenStocktake = false;
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredApprovalAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
    const [currentStocktakeId, setCurrentStocktakeId] = React.useState(props.selStocktakeId ? props.selStocktakeId : props.currentStocktakeId);
    const isComplete = currentStocktake ? currentStocktake.isComplete : false;
    const isApproved = currentStocktake ? (((currentStocktake.syncedToMYOB) || (currentStocktake.approvedBy !== null)) ? true : false) : false;
    const dummyFunction = async () => { return [] };
    const getInvData = React.useCallback(async () => {
        return await getAPIDataParamsReturn('/Inventory/Get', { WarehouseId: props.warehouseId }).then((data) => {
            return data = data.filter((d) => {
                if (frozenStocktake) {
                    return d.stockItem?.isFrozen;
                } else {
                    return !d.stockItem?.isFrozen;
                }
            });
        });
    }, [props.warehouseId]);
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(data);
            var specs = buildSpeciesList(data);
            setSpeciesList(specs);
        });
    }, []);
    React.useEffect(() => {
        setLoading(true);
        setInventory(null);

        if (currentStocktakeId) {
            getAPIDataParamsReturn('/Inventory/GetStocktake', { StocktakeId: currentStocktakeId }).then((cs) => {
                const localIsApproved = ((cs.syncedToMYOB) || (cs.approvedBy !== null)) ? true : false;
                const localIsComplete = ((cs.isComplete)) ? true : false;
                (localIsApproved ? dummyFunction() : getInvData()).then((data) => {
                    setCreateLoading(false);
                    setLoading(false);
                    var nFL = [];
                    var nInv = [];
                    var nonNewEntries = [];
                    for (var i = 0; i < data.length; i++) {
                        var ExistCS = cs.stocktakeLines.find((c) => ((c.stockItemId === data[i].stockItemId) && (c.expiryDate === data[i].expiryDate) && (c.lotSerialNbr === data[i].lotSerialNbr)));
                        if (ExistCS) {
                            nonNewEntries.push(ExistCS);
                        }
                        var Approved = false;
                        if (ExistCS?.isApproved === null || ExistCS?.isApproved === undefined) {
                            Approved = (ExistCS?.newQuantity === data[i].portalQuantity);
                        } else {
                            Approved = ExistCS?.isApproved;
                        }
                        if (!(localIsComplete && !ExistCS)) {
                            nFL.push({ stocktakeLineId: ExistCS?.id, isApproved: ExistCS?.isApproved, id: i, quantity: ExistCS ? ExistCS.newQuantity : null, Approved: Approved });
                            nInv.push({ stocktakeLineId: ExistCS?.id, ...data[i], id: i, touched: ExistCS ? true : false, stocktakeQuantity: ExistCS ? ExistCS.newQuantity : null, Approved: Approved, stocktakeOldQuantity: ExistCS ? ExistCS.oldQuantity : null });
                        }
                    }
                    for (var i = 0; i < cs.stocktakeLines.length; i++) {
                        if (!nonNewEntries.find((nne) => (nne.id === cs.stocktakeLines[i].id))) {
                            var newId = uuidv4();
                            var Approved = false;
                            if (cs.stocktakeLines[i]?.isApproved === null || cs.stocktakeLines[i]?.isApproved === undefined) {
                                Approved = false;
                            } else {
                                Approved = cs.stocktakeLines[i]?.isApproved;
                            }
                            nFL.push({ stocktakeLineId: cs.stocktakeLines[i].id, isApproved: cs.stocktakeLines[i].isApproved, Approved: Approved, id: newId, quantity: cs.stocktakeLines[i].newQuantity });
                            nInv.push({ stocktakeLineId: cs.stocktakeLines[i].id, ...cs.stocktakeLines[i], id: newId, Approved: Approved, touched: true, stocktakeQuantity: cs.stocktakeLines[i].newQuantity, stocktakeOldQuantity: cs.stocktakeLines[i].oldQuantity });
                        }
                    }
                    setCurrentStocktake(cs);
                    setFullList(nFL);
                    setInventory(nInv);
                    if (cs.isComplete) {
                        setSortKey(null)
                    }
                    checkApprovedStatus(nFL);
                });
            });
        } else {
            // getInvData().then((data) => {
            //     setLoading(false);
            //     setFullList(data.map((dt, ind) => ({ id: ind, quantity: null })));
            //     setInventory(data.map((dt, ind) => ({ ...dt, id: ind })));
            // });
        }
    }, [reload, props.reload, props.warehouseId, currentStocktakeId]);

    React.useEffect(() => {
        const remNoStockItems = (stockItems) => {
            var newStockItems = stockItems.filter((si) => {
                return !si.isNonStockItem;
            });
            setStockItems(newStockItems);
        }
        if (newLines) {
            getAPIData('/StockItems/Get', remNoStockItems)
        }
    }, [newLines]);

    const checkApprovedStatus = (list) => {
        for (var i = 0; i < list.length; i++) {
            if (list[i].Approved && !list[i].isApproved) {
                var params = {
                    StocktakeLineId: list[i].stocktakeLineId,
                    Approver: user.name,
                    isApproved: true
                }
                postApi('/Inventory/ApproveStocktake', params);
            }
        }
    }

    const convertToValue = (arr) => {
        return arr[0] + '-' + arr[1];
    }
    const deconstructValue = (val) => {
        return val.split('-').map((v) => {

            if (v === 'null') {
                return null
            } else if (v == 'true') {
                return true
            } else if (v == 'false') {
                return false
            } else {
                return Number(v)
            }
        }
        )
    }
    const validate = (complete) => () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!staffMember) {
            errorMessages.push("You must enter a Staff Member");
        }
        if (complete) {
            for (var i = 0; i < fullList.length; i++) {
                if (fullList[i].quantity === null) {
                    errorMessages.push("You must report a status for each item in this list");
                    break;
                } else {
                    if (fullList[i].quantity < 0) {
                        errorMessages.push("You must enter a quantity greater than or equal to 0 for all items.");
                        break;
                    }
                }
            }
        }
        for (var i = 0; i < newList.length; i++) {
            if (!newList[i].stockItem) {
                errorMessages.push("You must select a stock item for all new lines");
                break;
            }
            if (!newList[i].lotSerialNbr) {
                errorMessages.push("You must select a lot serial number for all new lines");
                break;
            }
            if (!newList[i].expiryDate) {
                errorMessages.push("You must select an expiry date for all new lines");
                break;
            }
            var quant = fullList.find((fl) => fl.id === newList[i].id)?.quantity;
            if (!quant || quant === '') {
                errorMessages.push("You must enter a quantity for all new lines");
                break;
            }
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else {
            submit(complete);
        }
    }
    const createNew = async () => {
        if (!currentStocktakeId) {
            setCreateLoading(true);
            //create stocktake
            postApi('/Inventory/CurrentStocktake', { WarehouseId: props.warehouseId, StaffMemberId: staffMember?.id }).then(async (currStocktake) => {
                var cst = await currStocktake.json()
                setCurrentStocktakeId(cst.id);


            });
        }
    }
    const submit = async (complete) => {
        var stocktakeId = currentStocktake ? currentStocktake.id : null;

        setLoading(true);
        var promArr = [];

        if (complete) {
            var paramsc = {
                StocktakeId: stocktakeId
            }
            await postApi('/Inventory/CompleteStocktake', paramsc);
        }


        setLoading(false);
        setCompleted(true);
        if (props.reload) {
            props.reload();
        }
    }
    const approve = async () => {
        setLoading(true);

        if (editST) {
            validate(false)();
        }
        // if (true) {
        //     await postApi('/Inventory/ApproveStocktake', { StocktakeId: currentStocktake.id, Approver: user.name });
        // } else {
        //     for (var i = 0; i < fullList.length; i++) {
        //         if (fullList[i].Approved) {
        //             var params = {
        //                 StocktakeId: currentStocktake.id,
        //                 StocktakeLineId: fullList[i].stocktakeLineId,
        //                 Approver: user.name
        //             }
        //             await postApi('/Inventory/ApproveStocktake', params);
        //         }
        //     }
        // }

        setLoading(false);
        setCompleted(true);
        if (props.reload) {
            props.reload();
        }
    }
    const sortBy = (key, second) => () => {
        if ((sortKey?.main === key) && (sortKey?.second === second)) {
            setSortKey({ main: key, second: second, pos: !sortKey.pos });
        } else {
            setSortKey({ main: key, second: second, pos: true });
        }
    }
    const setFullListById = async (id, quantity) => {
        var find = fullList.find((fl) => fl.id === id);
        if ((quantity !== null) && (quantity !== undefined) && (quantity !== '')) {
            if (find.new) {
                var invSel = newList.find((inv) => inv.id === find.id);
                if (invSel && invSel.stockItem && invSel.lotSerialNbr && invSel.expiryDate) {
                    var params = {
                        StocktakeId: currentStocktake.id,
                        LotSerialNbr: invSel.lotSerialNbr,
                        StockItemId: invSel.stockItem.id,
                        ExpiryDate: textfieldDateFormat(new Date(invSel.expiryDate)),
                        Quantity: quantity
                    }
                    postApi('/Inventory/AddUpdateStockTakeItem', params).then(async (data) => {
                        var list = await data.json();
                        setFullList(prevFullList => {
                            var returnedStockline = list.find((l) => l.stockItemId === params.StockItemId && textfieldDateFormat(new Date(l.expiryDate)) === params.ExpiryDate && l.lotSerialNbr === params.LotSerialNbr);
                            var findId = prevFullList.findIndex((fl) => fl.id === id);
                            prevFullList[findId].stocktakeLineId = returnedStockline?.id;
                            prevFullList[findId].quantity = quantity;
                            return prevFullList;
                        });
                    });
                }
            } else {
                var invSel = inventory.find((inv) => inv.id === find.id);
                if (invSel) {
                    var params = {
                        StocktakeId: currentStocktake.id,
                        LotSerialNbr: invSel.lotSerialNbr,
                        StockItemId: invSel.stockItemId,
                        ExpiryDate: textfieldDateFormat(new Date(invSel.expiryDate)),
                        Quantity: quantity
                    }
                    postApi('/Inventory/AddUpdateStockTakeItem', params);
                    setFullList(prevFullList => {
                        var findId = prevFullList.findIndex((fl) => fl.id === id);
                        prevFullList[findId].quantity = quantity;
                        return prevFullList;
                    });
                } else {
                    console.log('error lists dont match');
                }
            }
        }
    };
    const searchFilter = (data) => {
        var filterButton = deconstructValue(filterChoice);
        console.log(filterButton, data.stockItem);
        if (((filterButton[0] === null) || (filterButton[0] === data.stockItem?.speciesId)) && ((filterButton[1] === null) || (filterButton[1] === data.stockItem?.isHalfshell))) {

            if ((search === '') || (!search)) return true;
            if (data.stockItem?.description.toLowerCase().includes(search?.toLowerCase()) ||
                data.lotSerialNbr?.toLowerCase().includes(search?.toLowerCase()) ||
                data.estuary?.description.toLowerCase().includes(search?.toLowerCase())) {
                return true;
            }
        }
        return false;
    }
    const addNewLine = React.useCallback(() => {
        var newId = uuidv4();
        setFullList(prevFullList => {
            return [{ id: newId, new: true, quantity: null }, ...prevFullList];
        });
        setInventory(invList => {
            return [{ id: newId, new: true }, ...invList];
        });
        setNewList(newL => {
            return [{ id: newId, new: true }, ...newL];
        })
        setNewLines(true);
        setSearch('');
    }, []);


    const updateNewList = (id, expiryDate, lotSerialNbr, stockItem, quantity) => {
        setNewList(prevNewL => {
            var findId = prevNewL.findIndex((fl) => fl.id === id);
            if (expiryDate) {
                prevNewL[findId].expiryDate = expiryDate;
            }
            if (lotSerialNbr) {
                prevNewL[findId].lotSerialNbr = lotSerialNbr;
            }
            if (stockItem) {
                prevNewL[findId].stockItem = stockItem;
            }
            return prevNewL;
        });
        setFullListById(id, quantity);
    }

    const removeNewLine = (id) => {
        setFullList(prevFullList => {
            return prevFullList.filter((fl) => fl.id !== id);
        });
        setInventory(invList => {
            return invList.filter((fl) => fl.id !== id);
        });
        setNewList(newL => {
            return newL.filter((fl) => fl.id !== id);
        });
        var lineId = fullList.find((fl) => fl.id === id)?.stocktakeLineId;
        if (lineId) {
            postApi('/Inventory/DeleteStockTakeLine', { stocktakeLineId: lineId });
        } else {
            console.log('no line id- regular delte');
        }
    };
    const markApproval = (id, val) => {
        setFullList(prevFullList => {
            var findId = prevFullList.findIndex((fl) => fl.id === id);
            prevFullList[findId]['Approved'] = val;
            return prevFullList;
        });
        var findIT = fullList.find((fl) => fl.id === id);
        var params = {
            StocktakeLineId: findIT.stocktakeLineId,
            Approver: user.name,
            isApproved: val
        }
        postApi('/Inventory/ApproveStocktake', params);
    }
    const inventorySort = React.useMemo(() => {
        return inventory?.sort((a, b) => {
            if (sortKey === null) {
                return (a.portalQuantity !== a.stocktakeQuantity) === (b.portalQuantity !== b.stocktakeQuantity) ? 0 : (a.portalQuantity !== a.stocktakeQuantity) ? -1 : 1;
            } else {
                var aOp = sortKey.second ? a[sortKey.main]?.[sortKey.second] : a[sortKey.main];
                var bOp = sortKey.second ? b[sortKey.main]?.[sortKey.second] : b[sortKey.main];

                if (aOp > bOp) {
                    return (sortKey.pos ? 1 : -1);
                } else if (aOp < bOp) {
                    return (sortKey.pos ? -1 : 1);
                }
                return 0;
            }
        })
    }, [inventory, sortKey]);
    if (!currentStocktakeId) {
        return <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Grid container spacing={2} mb={1}>

                <Grid item xs={12} sm={12} md={6}>
                    <StaffMember staffId={null} driver={staffMember} setDriver={setStaffMember} disabled={currentStocktake} noTitle />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <SubmitButtonDisplayer error={error} completed={completed} loading={createLoading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={createNew} handleClose={props.handleClose} title='Create New' />
                </Grid>
            </Grid>
        </Box >
    } else {

        return (
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '24ch' },
                }}
                noValidate
                autoComplete="off"
            >

                <Div>
                    <Grid container spacing={2} mb={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <StaffMember staffId={currentStocktake ? currentStocktake.staffMemberId : null} driver={staffMember} setDriver={setStaffMember} disabled={currentStocktake} noTitle />
                            <SelectWarehouse useCache warehouseId={props.warehouseId ? props.warehouseId : null} state={warehouse} setState={setWarehouse} disabled={true} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            {isComplete ?
                                <div style={{ paddingTop: '8px', textAlign: 'left' }}>
                                    <SubmitButtonDisplayer error={error} completed={completed || isApproved} loading={loading} allowed={checkAccess(props.access, requiredApprovalAccess)} submitFunc={approve} handleClose={props.handleClose} title='Approve Selected' />
                                </div>
                                : <React.Fragment><div style={{ paddingTop: '8px', textAlign: 'left' }}>
                                    <SubmitButtonDisplayer error={error} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate(false)} handleClose={props.handleClose} title='Save' />
                                </div>
                                    <div style={{ paddingTop: '8px', textAlign: 'left' }}>
                                        <SubmitButtonDisplayer error={error} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate(true)} handleClose={props.handleClose} />
                                    </div>
                                </React.Fragment>}
                        </Grid>
                    </Grid>

                    <h3 style={{ fontWeight: 450, display: 'inline' }}>Stock</h3>
                    {!isApproved && isComplete && checkAccess(props.access, requiredApprovalAccess) ? <IconButton aria-label="Label" onClick={() => { setEditST(true) }} >
                        <EditIcon />
                    </IconButton> : null}
                    {!isComplete || (isComplete && editST) ? <IconButton aria-label="Label" onClick={() => { addNewLine() }} >
                        <AddIcon />
                    </IconButton> : null}
                    <JumboSearch onChange={(val) => { setSearch(val) }} />
                    <ToggleButtonGroup value={filterChoice} exclusive onChange={(ev, val) => setFilterChoise(val)} aria-label="text alignment">
                        <ToggleButton value={convertToValue([null, null])} aria-label="left aligned">
                            All
                        </ToggleButton>
                        <ToggleButton value={convertToValue([null, true])} aria-label="left aligned">
                            Halfshell
                        </ToggleButton>
                        {speciesList.map((sp) => {
                            return <ToggleButton value={convertToValue([sp.id, true])} aria-label="left aligned">
                                {sp.name} Halfshell
                            </ToggleButton>

                        })}
                        <ToggleButton value={convertToValue([null, false])} aria-label="left aligned">
                            Live
                        </ToggleButton>
                        {speciesList.map((sp) => {
                            return <ToggleButton value={convertToValue([sp.id, false])} aria-label="left aligned">
                                {sp.name} Live
                            </ToggleButton>

                        })}
                    </ToggleButtonGroup>
                    <Grid container spacing={2} mb={1}>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell onClick={sortBy('expiryDate')} >Use by Date<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                        <TableCell onClick={sortBy('lotSerialNbr')}>Lot Serial Nbr<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                        <TableCell onClick={sortBy('stockItem', 'description')}>StockItem<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                        <TableCell onClick={sortBy('estuary', 'description')}>Estuary<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                        {isComplete ? <TableCell onClick={sortBy('portalQuantity')}>Old Quantity (Doz)<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell> : null}
                                        <TableCell onClick={sortBy('portalQuantity')}>{isComplete ? "New Quantity (Doz)" : "Quantity (Doz)"}<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                        {isComplete && !isApproved ? <TableCell>Approve</TableCell> : <TableCell></TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inventorySort ? (inventorySort).map((row, index) => {
                                        return <ModiTableRow show={searchFilter(row)} key={row.id} row={row} index={index} markApproval={markApproval} setFullListById={setFullListById} isComplete={isComplete} isApproved={isApproved} disabled={isApproved || (isComplete && !editST) || completed} frozenStocktake={frozenStocktake} stockItems={stockItems} updateNewList={updateNewList} removeNewLine={removeNewLine} />

                                    }) : null}
                                </TableBody>
                            </Table>
                        </Grid>

                    </Grid>
                </Div>
            </Box >
        );
    }
};

const ModiTableRow = (props) => {
    const newLine = props.row.new ? true : false;
    const [quantity, setQuantity] = React.useState(props.row.touched ? props.row.stocktakeQuantity : props.row.portalQuantity);
    const [keep, setKeep] = React.useState(null);
    const [expiryDate, setExpiryDate] = React.useState(null);
    const [lotSerialNbr, setLotSerialNbr] = React.useState(null);
    const [stockItem, setStockItem] = React.useState(null);
    const [markApproval, setMarkApproval] = React.useState(props.row.Approved);
    const { theme } = useJumboTheme();
    var statColor = 'white';
    console.log(props);
    var compareQuantity = props.row.portalQuantity ? props.row.portalQuantity : 0;
    if (props.isComplete) {
        compareQuantity = (props.row.stocktakeOldQuantity ? props.row.stocktakeOldQuantity : 0)
    }
    if ((Number(compareQuantity) !== Number(quantity)) && (quantity !== 0)) {
        statColor = theme.palette.oysterProducts.open;
    } else if ((keep === true) || (props.row.touched && (Number(compareQuantity) === Number(quantity)))) {
        statColor = theme.palette.oysterProducts.completed;
    } else if ((keep === false) || (props.row.touched && (0 === Number(quantity)))) {
        statColor = theme.palette.oysterProducts.cancelled;
    }

    const updateLotSerialNbr = (lsn) => {
        setLotSerialNbr(lsn);
        var newExpDate = adjustDateByStockItem(undoDateFormat(lsn.substring(lsn.length - 8, lsn.length)), stockItem)
        setExpiryDate(newExpDate);
        props.updateNewList(props.row.id, newExpDate, lsn, stockItem, quantity);
    }
    const updateStockItem = (si) => {
        setStockItem(si);
        if (lotSerialNbr) {
            var newExpDate = adjustDateByStockItem(undoDateFormat(lotSerialNbr.substring(lotSerialNbr.length - 8, lotSerialNbr.length)), si)
            setExpiryDate(newExpDate);
        }
        props.updateNewList(props.row.id, newExpDate, lotSerialNbr, si, quantity);
    }
    const updateExpiryDate = (ed) => {
        setExpiryDate(ed);
        props.updateNewList(props.row.id, ed, lotSerialNbr, stockItem, quantity);
    }
    if (!props.show) {
        return null;
    }
    return <TableRow key={props.row.id} style={{ backgroundColor: statColor }} >
        <TableCell>{!newLine ? dateFormat(props.row.expiryDate) :
            <DatePicker date={expiryDate} setDate={updateExpiryDate} label="Date" />
        }
        </TableCell>
        <TableCell>{!newLine ? props.row.lotSerialNbr :
            <SelectLotSerialNbr state={lotSerialNbr} setState={updateLotSerialNbr} />}
        </TableCell>
        <TableCell>{!newLine ? props.row.stockItem?.description :
            <SelectTextField key={'si' + props.row.id} title="Stock Item" state={stockItem} nameFunc={(r) => r.description} list={props.stockItems?.filter((si) => {
                if (props.frozenStocktake) {
                    return ((si.isFrozen));
                } else {
                    return (!(si.isFrozen));
                }
            })} setState={updateStockItem} disabled={props.disabled} />}
        </TableCell>
        <TableCell>{!newLine ? props.row.estuary?.description : (lotSerialNbr ? lotSerialNbr.estuary?.description : null)}
        </TableCell>

        {props.isComplete ? <TableCell><ControlledTextField key={'bi' + props.row.id} title="" state={Number(compareQuantity)} setState={(quant) => { }} style={{ "-webkit-text-fill-color": '#475259' }} disabled={true} number width={'100px'} />
        </TableCell> : null}
        <TableCell><ControlledTextField key={'bi' + props.row.id} title="" state={quantity} updateDataOnBlur={(quant) => { props.setFullListById(props.row.id, quant) }} setState={(quant) => { setQuantity(quant) }} style={props.disabled ? { "-webkit-text-fill-color": '#475259' } : {}} disabled={props.disabled} number width={'100px'} />
        </TableCell>
        {props.isComplete && !props.isApproved ?
            <TableCell>
                <FormGroup>
                    <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox checked={markApproval} onChange={(event) => { setMarkApproval(event.target.checked); props.markApproval(props.row.id, event.target.checked) }} />} label="" />
                </FormGroup>
            </TableCell>
            : null}
        <TableCell>
            {!props.disabled ? <IconButton aria-label="Label" onClick={() => { setKeep(true); setQuantity(props.row.portalQuantity ? props.row.portalQuantity : 0); props.setFullListById(props.row.id, (props.row.portalQuantity ? props.row.portalQuantity : 0)) }} >
                <DoneIcon />
            </IconButton> : null}
            {!props.disabled ? <IconButton aria-label="Label" onClick={() => { setKeep(false); setQuantity(0); props.setFullListById(props.row.id, 0) }} >
                <CloseIcon />
            </IconButton> : null}
            {newLine ? <IconButton aria-label="Label" onClick={() => { props.removeNewLine(props.row.id) }} >
                <DeleteIcon />
            </IconButton> : null}
        </TableCell>


    </TableRow>
}

export default PriceForm;

const SelectLotSerialNbr = (props) => {
    const [prn, setPrn] = React.useState('');
    const [returnedLSNs, setReturnedLSNs] = React.useState([]);
    const [loadingLSN, setLoadingLSN] = React.useState(false);
    const [lotNbr, setLotNbr] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [loadingDetails, setLoadingDetails] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    }
    const searchPRN = () => {
        setLoadingLSN(true);
        getAPIDataParamsReturn('/Inventory/SearchPRN', { LotNbrSubString: prn }).then((rlsn) => {
            setReturnedLSNs(rlsn);
            if (rlsn.length === 1) {
                setLotNbr(rlsn[0]);
            }
            setLoadingLSN(false);
        });
        setOpen(true);
    }
    const selectLSN = (LSN) => () => {
        setLotNbr(LSN);
        setOpen(false);
        props.setState(LSN);
    }
    return (lotNbr ?
        <div>{lotNbr}
            <IconButton aria-label="Label" onClick={() => { setLotNbr(null) }} >
                <CloseIcon />
            </IconButton>
        </div>
        : <React.Fragment><FormModal open={open} handleClose={handleClose} title={""} fixedWidth>
            <Typography variant="h6" id="modal-modal-title"> Choose a Lot Serial Number</Typography>
            {loadingLSN ? <CircularProgress /> : (returnedLSNs.length > 0 ? returnedLSNs.map((rlsn) => {
                return <Button style={{ marginTop: '20px' }} onClick={selectLSN(rlsn)} variant="outlined" disabled={false} >{rlsn}</Button>
            }) : 'No Matching Lot Serial Numbers')}
        </FormModal >
            <TextField
                id="outlined-required"
                label="PRN"
                value={prn}
                onChange={(e) => setPrn(e.target.value)}
            />
            <Button style={{ marginTop: '20px' }} onClick={searchPRN} variant="outlined" disabled={false} ><SearchIcon /></Button>
        </React.Fragment>)
}
